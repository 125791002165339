import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <div className="u-txt-c">
      <div>

        <h2 className='u-pc-fz44 u-sp-fz22 u-fw-b u-txt-red u-pc-p50 u-sp-p20'>404</h2>
        <p className='u-pc-fz20 u-sp-fz16 u-txt-red u-fw-b'>Page not found</p>

        <section className="u-pc-mt50 u-sp-mt35">
          <h3 className='u-pc-fz24 u-sp-fz20 u-fw-b'>申し訳ございません。<br />ページが見つかりませんでした。</h3>
          <p className='u-pc-fz18 u-sp-fz15 u-pc-mt30 u-sp-mt15'>お探しのページは存在しないか、URLが変更されている可能性があります。<br />また、URLにタイプミスがないか再度ご確認ください。</p>
          <p className='u-pc-fz18 u-sp-fz15 u-pc-mt30 u-sp-mt15'><span className="u-pc-fz18 u-sp-fz15">I'm sorry. Oops Page Not Found</span><br />The page you are looking for does not exist or has been moved.</p>
          <a href="/" className="c-square-button -black u-pc-mt50 u-sp-mt25">
            <div className="c-square-button__text u-mt40 u-pc-fz22 u-sp-fz16">トップへ戻る（Go to Top）</div>
          </a>
        </section>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
